import React from 'react';
import classNames from 'classnames';
import { unreachable, ComputeShallow } from '~/ts-utils';
import { style, classes } from './call-to-action-button.st.css';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import Check from 'wix-ui-icons-common/on-stage/Check';
import {
  CALL_TO_ACTION_BUTTON,
  CREATE_REVIEW_BUTTON,
  VIEW_REVIEW_BUTTON,
} from '../../../common/constants/data-hooks';
import { ButtonExtended } from '~commons/components/button-extended/button-extended';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';

export type CallToActionButtonState = ComputeShallow<
  ({ type: 'CREATE_REVIEW' } | { type: 'VIEW_MY_REVIEW' }) & {
    onClick: () => void;
  }
>;
export const CallToActionButton: React.FC<{
  callToAction: CallToActionButtonState;
  className?: string;
}> = ({ callToAction, className }) => {
  const t = useTranslate();
  const { ctaButtonText } = useFormSettings();
  switch (callToAction.type) {
    case 'CREATE_REVIEW':
      return (
        <ButtonExtended
          className={style(classes.root, className)}
          onClick={callToAction.onClick}
          size="large"
          mapping="callToAction"
          data-hook={classNames(CREATE_REVIEW_BUTTON, CALL_TO_ACTION_BUTTON)}
        >
          {ctaButtonText}
        </ButtonExtended>
      );
    case 'VIEW_MY_REVIEW':
      return (
        <ButtonExtended
          className={style(classes.root, className)}
          onClick={callToAction.onClick}
          size="large"
          mapping="callToAction"
          data-hook={classNames(VIEW_REVIEW_BUTTON, CALL_TO_ACTION_BUTTON)}
        >
          <Check />
          {t('call-to-action-button.see-your-review')}
        </ButtonExtended>
      );
    default:
      throw unreachable(callToAction);
  }
};
