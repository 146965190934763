import React from 'react';
import { CRASH_STATE_MESSAGE, HEADER_TITLE } from '../../../common/constants/data-hooks';
import { TextExtended } from '../text-extended/text-extended';
import AppWrapper from '../app-wrapper/app-wrapper';
import { HorizontalDivider } from '../dividers';
import { ButtonExtended } from '~commons/components/button-extended/button-extended';
import { st, classes } from './fallback-state.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';

export const FallbackState = ({ errorCode }: { errorCode?: string | null }) => {
  const { isMobile, isRTL } = useEnvironment();
  const t = useTranslate();

  console.log('FallbackState', errorCode);
  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(
        () => ({ mobile: isMobile, rtl: isRTL, isExtendedPaletteEnabled: true }),
        [isMobile, isRTL],
      )}
    >
      <AppWrapper>
        {isMobile && <HorizontalDivider />}
        <div className={st(classes.headerContainer, { isMobile })}>
          <div data-hook={HEADER_TITLE} className={classes.left}>
            <TextExtended className={classes.commentCount}>
              {t('filters-row.all-reviews', { count: 0 })}
            </TextExtended>
          </div>
        </div>
        <div className={st(classes.container, { isMobile })}>
          <div className={classes.text} data-hook={CRASH_STATE_MESSAGE}>
            <TextExtended>{t('error-boundary-fallback.message')}</TextExtended>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonExtended mapping="callToAction" onClick={() => window.location.reload()}>
              {t('error-boundary-fallback.button-label')}
            </ButtonExtended>
          </div>

          {errorCode && (
            <div className={classes.text}>
              <TextExtended className={classes.error}>ErrorId: {errorCode}</TextExtended>
            </div>
          )}
        </div>
      </AppWrapper>
    </TPAComponentsProvider>
  );
};
