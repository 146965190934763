import React, { useEffect } from 'react';
import {
  EMPTY_STATE_CTA,
  EMPTY_STATE_ZERO_REVIEWS_CONTENT,
  EMPTY_STATE_ZERO_REVIEWS_TITLE,
} from './data-hooks';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useApi } from '../../components/api-provider/use-api';
import TextExtended from '../../components/text-extended/text-extended';
import { classes, st } from './zero-reviews-empty-state.st.css';
import { ButtonExtended } from '~commons/components/button-extended/button-extended';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';

const ZeroReviewsEmptyState = () => {
  const t = useTranslate();
  const { ctaButtonText } = useFormSettings();
  const { isMobile } = useEnvironment();
  const { biShowZeroReviewsEmptyState, callToActionClicked, biClickLeaveReviewBtn } = useApi(
    (state, actions) => ({
      biShowZeroReviewsEmptyState: actions.biShowZeroReviewsEmptyState,
      callToActionClicked: actions.callToActionClicked,
      biClickLeaveReviewBtn: actions.biClickLeaveReviewBtn,
    }),
  );

  useEffect(() => {
    biShowZeroReviewsEmptyState();
  }, [biShowZeroReviewsEmptyState]);

  return (
    <div className={st(classes.container, { mobile: isMobile })}>
      <TextExtended
        dataHook={EMPTY_STATE_ZERO_REVIEWS_TITLE}
        className={classes.title}
        size={isMobile ? 16 : 20}
      >
        {t('empty-state.zero-reviews-title')}
      </TextExtended>
      <TextExtended
        dataHook={EMPTY_STATE_ZERO_REVIEWS_CONTENT}
        className={classes.text}
        size={isMobile ? 14 : 16}
      >
        {t('empty-state.zero-reviews-text')}
      </TextExtended>
      <ButtonExtended
        className={classes.cta}
        onClick={() => {
          biClickLeaveReviewBtn({ origin: 'empty_state' });
          return callToActionClicked({ button: 'create' });
        }}
        mapping="callToAction"
        size="large"
        data-hook={EMPTY_STATE_CTA}
      >
        {ctaButtonText}
      </ButtonExtended>
    </div>
  );
};

export default ZeroReviewsEmptyState;
