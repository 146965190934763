export const getModifiedFields = <T>(obj1: T, obj2: T): string[] => {
  const findDifferences = (o1: any, o2: any, path = ''): string[] => {
    const differences: string[] = [];

    for (const key in o1) {
      const currentPath = path ? `${path}.${key}` : key;

      if (Array.isArray(o1[key]) && Array.isArray(o2[key])) {
        // If arrays differ, add the array path
        if (
          o1[key].length !== o2[key].length ||
          o1[key].some((item: any, index: number) => item !== o2[key][index])
        ) {
          differences.push(currentPath);
        }
      } else if (
        typeof o1[key] === 'object' &&
        o1[key] !== null &&
        typeof o2[key] === 'object' &&
        o2[key] !== null
      ) {
        // Recursively check nested objects
        differences.push(...findDifferences(o1[key], o2[key], currentPath));
      } else if (o1[key] !== o2[key]) {
        // Add differing key if values are not the same
        differences.push(currentPath);
      }
    }

    // Check keys that exist in o2 but not in o1
    for (const key in o2) {
      const currentPath = path ? `${path}.${key}` : key;
      if (!(key in o1)) {
        differences.push(currentPath);
      }
    }

    return differences;
  };

  return findDifferences(obj1, obj2);
};

export const hasModifiedFields = <T>(a: T, b: T): boolean => getModifiedFields<T>(a, b).length > 0;
