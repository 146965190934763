import React from 'react';
import ReviewForm from './review-form';
import { useApi } from '../api-provider/use-api';
import { REVIEW_BOX_TOP } from '../../../common/constants/data-hooks';
import { NewReviewFormState } from '../../../common/store/reviews/reviews/review-state-types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { ROOT_FORM_CLASS } from '~reviews/common/services/id-utils';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { hasModifiedFields } from '~reviews/common/services/content-compare';
import { Member } from '~reviews/common/api-types';
import { FormContent } from '~commons/components/review-form/types';

export const DEFAULT_REVIEW_CONTENT: FormContent = {
  name: '',
  email: '',
  title: '',
  body: '',
  rating: 0,
  media: [],
};

const RootReviewForm: React.FC<{
  formState: NewReviewFormState;
  className?: string;
}> = ({ formState, className }) => {
  const { isEditor } = useEnvironment();
  const t = useTranslate();

  const {
    createReview,
    discardCreatingReview,
    biCancelCreatingReview,
    currentMember,
    biClickPublishReview,
    biFormValidationError,
    forceHoverState,
    forceErrorState,
  } = useApi((state, actions) => {
    return {
      createReview: actions.createReview,
      discardCreatingReview: actions.discardCreatingReview,
      biCancelCreatingReview: actions.biCancelCreatingReview,
      currentMember:
        state.members.currentOwner.type === 'MEMBER'
          ? state.members.currentOwner.member
          : undefined,
      biClickPublishReview: actions.biClickPublishReview,
      biFormValidationError: actions.biFormValidationError,
      forceHoverState: formState.type === 'INITIAL' && formState.effects === 'Hover',
      forceErrorState: formState.type === 'INITIAL' && formState.effects === 'Error',
    };
  });

  if (formState.type === 'HIDDEN') {
    return null;
  }

  const name = currentMember ? buildAuthorName(currentMember) : '';
  const initialContent = isEditor
    ? {
        ...DEFAULT_REVIEW_CONTENT,
        name,
        email: currentMember?.loginEmail ?? '',
        title: t('editor-field-input.placeholder-text'),
        body: t('editor-field-input.placeholder-text'),
      }
    : { ...DEFAULT_REVIEW_CONTENT, name };

  return (
    <ReviewForm
      id="root-review-form"
      className={classNames(className, ROOT_FORM_CLASS)}
      dataHook={REVIEW_BOX_TOP}
      isPending={formState.type === 'REVIEW_CREATE_PENDING'}
      initialContent={initialContent}
      error={formState.type === 'REVIEW_CREATE_ERROR' ? formState.error : undefined}
      forceHoverState={forceHoverState}
      forceErrorState={forceErrorState}
      onCancel={({ content, contact, reset }) =>
        hasModifiedFields({ ...content, ...contact }, initialContent)
          ? {
              type: 'PROMPT',
              discard: 'COMMENT',
              onConfirm: () => {
                reset();
                discardCreatingReview({});
                biCancelCreatingReview({ review: { content }, isEdited: false });
              },
            }
          : {
              type: 'SILENT_CANCEL',
              cleanup: () => {
                reset();
                discardCreatingReview({});
                biCancelCreatingReview({ review: { content }, isEdited: false });
              },
            }
      }
      onValidationFailed={(error) => {
        biFormValidationError({ missingRequiredFields: error ?? [] });
      }}
      requireEmail={!currentMember}
      onSubmit={({ content, contact }) => {
        biClickPublishReview({ review: { content }, isEdited: false });
        createReview({
          content,
          contact,
        });
      }}
    />
  );
};

const buildAuthorName = (member: Member): string =>
  [member?.contact?.firstName, member?.contact?.lastName].filter((n) => n).join(' ') ||
  member?.profile?.nickname ||
  '';

export default RootReviewForm;
